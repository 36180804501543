import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

// Actions
import {
  getCampaignPlanOverview,
  changeCampaignPlanStatus,
} from "../../../actions/campaign-plan/CampaignPlanActions";
import { getBasicCampaignDetails } from "../../../actions/campaign/CampaignBasicInfoActions";

// Utils
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";
import { useGetTgInfo } from "../../../mavin/utils/hooks/HooksUtil";

// Components
import CampaignSidebar from "../../../components/campaign-sidebar/CampaignSidebar";
import { isAgencySpecific } from "../../../components/hoc/MediaAgencySpecific";
import {
  CampaignDetails,
  CancelCampaign,
  ScheduleCampaign,
} from "../components/HeaderNav";
import CityDetailsTable from "../components/CityDetailsTable";
import CampaignViewCards from "../components/CampaignViewCards";
import { CampaignState } from "../components/planningConstants";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Page-Component
function HeaderSection({ campaign }) {
  const dispatch = useDispatch();
  const history = useHistory();

  function scheduleCampaign() {
    const redirectUrl = `/campaign/${campaign.id}/schedule`;

    dispatch(
      changeCampaignPlanStatus(
        campaign.id,
        CampaignState.SCHEDULED,
        history,
        redirectUrl
      )
    );
  }

  if (!campaign) {
    return null;
  }
  const EnableCancelCampaign = isAgencySpecific(CancelCampaign);
  const EnableScheduleCampaign = isAgencySpecific(ScheduleCampaign);

  const pageTitle = <CampaignDetails campaign={campaign} />;
  const pageActions = (
    <div className="d-flex">
      {EnableCancelCampaign && (
        <EnableCancelCampaign campaignId={campaign.id} history={history} />
      )}
      {EnableScheduleCampaign && (
        <EnableScheduleCampaign scheduleCampaign={scheduleCampaign} />
      )}
    </div>
  );

  return <PageHeader title={pageTitle} actions={pageActions} shadow={true} />;
}

/**
 * Page
 */
function CampaignConfirmOverviewPage() {
  // State
  const { campaignId } = useParams();

  // Get campaignPlan
  const campaignPlanOverview = useSelector(
    (state) => state.campaignPlan.campaignPlanOverview
  );

  // Get campaign
  const campaign = useSelector((state) => state.c_campaignBasicInfo.campaign);

  // Tg name
  const tgName = useSelector((state) => state.orgTargetGroup.tgName);

  const { summary = {}, planByCity = {} } = campaignPlanOverview;

  // TODO:: once the api is returning target group id ,
  // Get "campaign plan overview" api
  // target group id
  const targetGroupId = Object.values(planByCity)[0]?.targetGroupId;

  /// Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCampaignPlanOverview(campaignId));
    dispatch(getBasicCampaignDetails(campaignId));
  }, [dispatch, campaignId]);

  // dispatching target group action
  useGetTgInfo(targetGroupId);

  if (!campaignPlanOverview || !campaign) {
    return null;
  }

  const redirectLinkObj = {
    campaignOverviewLink: `/campaign/${campaignId}/confirm`,
    citySummaryLink: `/campaign/${campaignId}/city/:cityId/confirm`,
  };

  return (
    <>
      {/* SideBar */}
      <CampaignSidebar campaign={campaign} redirectLinkObj={redirectLinkObj} />

      {/* Content-Section */}
      <div className="content-wrapper">
        {/* Page Header Section */}
        <HeaderSection campaign={campaign} />

        {/* Page-Content */}
        <div className="page-content">
          {/* campaign view Cards Section */}
          <CampaignViewCards
            tgName={tgName}
            campaign={campaign}
            campaignPlanSummary={summary}
            mediaCount={campaign.mediaCount}
          />

          {/* City Details Table */}
          <CityDetailsTable
            campaignPlanOverview={campaignPlanOverview.planByCity}
            campaign={campaign}
          />
        </div>
      </div>
    </>
  );
}

export default CampaignConfirmOverviewPage;
