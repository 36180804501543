export const CampaignState = {
  DRAFT: "DRAFT",
  REVIEW: "REVIEW",
  CONFIRMED: "CONFIRMED",
  LIVE: "LIVE",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED",
  SCHEDULED: "SCHEDULED",
  STOPPED: "STOPPED",
};

export const CampaignStateTest = {
  DRAFT: "Draft",
  REVIEW: "Waiting for confirmation",
  CONFIRMED: "Confirmed",
  SCHEDULED: "Scheduled",
  LIVE: "Live",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
};
