import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getCampaignList } from "../../../actions/campaign/CampaignBasicInfoActions";

// Components
import TableHeaders from "../../../components/table/TableHeaders";
import { CampaignStatus } from "../components/HeaderNav";
import Spinner from "../../../components/spinner/Spinner";
import { CampaignRedirect } from "../components/CampaignRedirect";

// Utils
import { formatText } from "../../../common-utils/string-utils/StringUtils";

const tableHeaders = [
  {
    title: {
      displayName: "Campaign Name",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "Client Name",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "Status",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "No Of Sites",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "Last Updated",
      className: "col-1",
    },
  },
  {
    title: {
      displayName: "Action",
      className: "col-1",
    },
  },
];

function CampaignRow({ campaign }) {
  if (!campaign) {
    return null;
  }
  const { title, buyerName, planStatus, mediaCount, updatedOn, id } = campaign;

  return (
    <tr>
      <td>{title}</td>
      <td>{formatText(buyerName)}</td>
      <td>
        <CampaignStatus state={planStatus} />
      </td>
      <td>{mediaCount}</td>
      <td>{new Date(updatedOn).toDateString()}</td>
      <td>
        <CampaignRedirect id={id} state={planStatus} />
      </td>
    </tr>
  );
}

function TableTitle() {
  return (
    <div className="d-flex justify-content-between">
      <h4 className="card-title my-1">{"Campaigns"}</h4>
    </div>
  );
}

function Loader() {
  return (
    <div className="d-flex justify-content-center border p-2 mt-3 bg-alt">
      <Spinner />
    </div>
  );
}

/**
 * Table Component
 */
function CampaignsTable() {
  // Selector States
  const campaigns = useSelector((state) => state.c_campaignList.camList);

  // Campaign Loading
  const campaignsLoading = useSelector(
    (state) => state.c_campaignList.camListLoading
  );

  // Dispatch
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCampaignList());
  }, [dispatch]);

  return (
    <div className="card shadow mt-5">
      <div className="card-body">
        {/* Table Title.. */}
        <TableTitle />

        {campaignsLoading ? (
          <Loader />
        ) : (
          <div className="table-responsive mt-3">
            <table className="table border table-reports">
              {/* Table Column Headers.. */}
              <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />

              {/* Table data.. */}
              <tbody>
                {campaigns.length > 0 ? (
                  campaigns.map((eachCampaign) => (
                    <CampaignRow
                      key={eachCampaign.id}
                      campaign={eachCampaign}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan={12}>
                      <span className="d-flex justify-content-center">
                        {"No Campaigns Present"}
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default CampaignsTable;
