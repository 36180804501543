import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// Actions
import { updateCampaignState } from "../../../actions/campaign/CampaignBasicInfoActions";

// Constants And Utils
import { constructRedirectPath } from "../../../utils/redirect-utils/RedirectUtils";
import { ProohFormDataTargets } from "../../constants/GeneralConstants";
import { formatText } from "../../../common-utils/string-utils/StringUtils";

// Components
import Spinner from "../../../components/spinner/Spinner";

// Other page Sections
import { CampaignState, CampaignStateTest } from "./planningConstants";

function StatusIcon({ displayText, textTypeClass, iconClass }) {
  return (
    <div className={`d-flex align-items-center ml-2 ${textTypeClass}`}>
      <i className={`fas ${iconClass}`}></i>
      <span className="ml-1">{displayText}</span>
    </div>
  );
}

export function CampaignStatus({ state }) {
  if (state === CampaignState.DRAFT) {
    return (
      <StatusIcon
        displayText={CampaignStateTest.DRAFT}
        textTypeClass="text-warning"
        iconClass="fa-exclamation-triangle"
      />
    );
  }

  if (state === CampaignState.REVIEW) {
    return (
      <StatusIcon
        displayText={CampaignStateTest.REVIEW}
        textTypeClass="text-warning"
        iconClass="fa-exclamation-triangle"
      />
    );
  }

  if (state === CampaignState.CONFIRMED) {
    return (
      <StatusIcon
        displayText={CampaignStateTest.CONFIRMED}
        iconClass="fa-clock"
      />
    );
  }

  if (state === CampaignState.SCHEDULED) {
    return (
      <StatusIcon
        displayText={CampaignStateTest.SCHEDULED}
        iconClass="fa-clock"
      />
    );
  }

  if (state === CampaignState.LIVE) {
    return (
      <StatusIcon
        displayText={CampaignStateTest.LIVE}
        textTypeClass="text-success"
        iconClass="fa-video"
      />
    );
  }

  if (state === CampaignState.COMPLETED) {
    return (
      <StatusIcon
        displayText={CampaignStateTest.COMPLETED}
        textTypeClass="text-success"
        iconClass="fa-check-circle"
      />
    );
  }

  if (state === CampaignState.CANCELLED) {
    // TODO: Set Cancelled Icon
    return (
      <StatusIcon
        displayText={CampaignStateTest.CANCELLED}
        textTypeClass="text-danger"
        iconClass="fa-window-close"
      />
    );
  }

  return null;
}

function getClientName(campaign) {
  const { planStatus, buyerName } = campaign;
  if (CampaignState.DRAFT === planStatus) {
    return "NA";
  }
  return buyerName;
}

export function CampaignDetails({ campaign }) {
  if (Object.keys(campaign).length < 1) {
    return null;
  }

  const { title = "New Campaign", planStatus } = campaign;
  const client = getClientName(campaign);

  return (
    <>
      <h3 className="mb-0">{title}</h3>
      <div className="d-flex align-items-center">
        <p className="mb-0">
          <span>Client: {formatText(client)} | </span>
          <span>
            Last Updated: {new Date(campaign.updatedOn).toDateString()} |
          </span>
        </p>
        <CampaignStatus state={planStatus} />
      </div>
    </>
  );
}

export function Review({ campaignId, cityId }) {
  const history = useHistory();
  function redirectToReviewPage() {
    history.push(
      constructRedirectPath(`/campaign/${campaignId}/city/${cityId}/review`)
    );
  }
  return (
    <button
      type="button"
      className="btn btn-primary m-2"
      onClick={redirectToReviewPage}
    >
      Review
    </button>
  );
}

export function SaveAsDraft() {
  const history = useHistory();

  function redirectToHomePage() {
    history.push(constructRedirectPath("/home"));
  }

  return (
    <button
      type="button"
      className="btn btn-outline-primary m-2"
      onClick={redirectToHomePage}
    >
      Save as Draft
    </button>
  );
}

export function StopCampaign({ campaignId, history }) {
  const dispatch = useDispatch();

  return (
    <button
      type="button"
      className="btn btn-primary"
      onClick={() =>
        dispatch(
          updateCampaignState(campaignId, CampaignState.STOPPED, history)
        )
      }
    >
      Stop Campaign
    </button>
  );
}

export function SendForConfirmation({ confirmAction }) {
  //Selector
  const sendForConfirmation = useSelector(
    (state) => state.campaignReview.campaignConfirmLoading
  );

  return (
    <button
      type="button"
      data-toggle="modal"
      disabled={sendForConfirmation}
      data-target={`#${ProohFormDataTargets.sendConfirmationForm}`}
      onClick={confirmAction}
      className="btn btn-primary m-2"
    >
      <span>Send For Confirmation</span>{" "}
      {sendForConfirmation && (
        <Spinner className="ml-1 text-dark spinner-border-sm" />
      )}
    </button>
  );
}

export function ContinueEditing({ onClickAction }) {
  return (
    <button
      type="button"
      className="btn btn-link shadow-none"
      onClick={onClickAction}
    >
      Continue Editing
    </button>
  );
}

export function ExtendCampaign({ campaignId }) {
  return (
    <button type="button" className="btn btn-primary">
      Extend Campaign
    </button>
  );
}

export function ShareCampaign({ campaignId }) {
  return (
    <button type="button" className="btn btn-primary">
      Share
      <i className="fa fa-share-alt pl-2"></i>
    </button>
  );
}

export function CancelCampaign({ campaignId, history }) {
  const dispatch = useDispatch();

  return (
    <button
      type="button"
      className="btn btn-link shadow-none"
      onClick={() =>
        dispatch(
          updateCampaignState(campaignId, CampaignState.CANCELLED, history)
        )
      }
    >
      Cancel Campaign
    </button>
  );
}

export function AddCityInCampaign({ onClickAction }) {
  return (
    <button
      type="button"
      className="btn btn-primary mx-2"
      onClick={onClickAction}
    >
      Add City
    </button>
  );
}

export function ConfirmCampaign({ confirmAction }) {
  // Selector
  const updateStateLoading = useSelector(
    (state) => state.campaignPlan.updateStateLoading
  );

  return (
    <button
      type="button"
      className="btn btn-primary mx-2"
      onClick={confirmAction}
    >
      <span>Confirm</span>{" "}
      {updateStateLoading && (
        <Spinner className="ml-1 text-dark spinner-border-sm" />
      )}
    </button>
  );
}

export function ScheduleCampaign({ scheduleCampaign }) {
  //Selector
  const updateStateLoading = useSelector(
    (state) => state.campaignPlan.updateStateLoading
  );
  return (
    <button
      type="button"
      className="btn btn-primary m-2"
      onClick={scheduleCampaign}
    >
      <span>Schedule</span>{" "}
      {updateStateLoading && (
        <Spinner className="ml-1 text-dark spinner-border-sm" />
      )}
    </button>
  );
}
